/* Styles for the div that encapsulates the entire accordion. */
.research-accordion {
  width: 95%;
}

/* Styles for the container that holds all of the tiles. */
.research-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

/* Styles for each of the individual research tiles. */
.tile {
  display: flex;
  align-items: center;
  align-self: stretch; /* Ensures that all the tiles in a row will take on the height of the tallest element. That way each row is uniform. */
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  /* background-image: url("https://drive.google.com/uc?id=1dsuS3QSBTEsw-7dfn0Pl8cVja5bP4Rx0"); */
  border-radius: 20px;
  border: none;
  margin: 8px;
  padding: 30px;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  height: auto;
  transition: all 0.2s ease-in-out;
}

/* 
  Styles for the header text of each tile.
  Also ensures that the titles are always centered in the middle of the tile. 
*/
.tile > p {
  width: 100%;
  color: white;
  font-family: "Spinnaker", sans-serif;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.tile:hover {
  transform: scale(1.05);
}

/* Desktop styles. */
@media only screen and (min-width: 768px) {
  .tile {
    max-width: 300px /* 20vw */;
  }

  .tile > p {
    font-size: 25px;
  }
}

/* Tablet styles. */
@media only screen and (max-width: 1024px) {
  .tile {
    max-width: 225px;
  }

  .tile > p {
    font-size: 20px;
  }
}

/* Tablets in landscape mode only. */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .tile {
    max-width: 20vw;
  }
}

/* Smartphone styles. */
@media only screen and (max-width: 768px) {
  .tile {
    max-width: 100%;
  }

  .tile > p {
    font-size: 18px;
  }
}
