/* Variable used for the timing of the fadeIn and moveDown animations. */
:root {
  --animation-timing: 1.25s;
}

/* Pre-animation state of the header and subheader when the page first loads. */
.invisible {
  opacity: 0;
  transform: translateY(-35px);
}

/**
  * Use this class when you specifically want both the fadeIn and moveDown animations to 
  * occur at the same time. The animations do not work properly if an element has both of
  * them separately.
*/
.fadeInMoveDown {
  -webkit-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -moz-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -ms-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -o-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
}

.fadeIn {
  -webkit-transition: opacity var(--animation-timing) ease-in;
  -moz-transition: opacity var(--animation-timing) ease-in;
  -ms-transition: opacity var(--animation-timing) ease-in;
  -o-transition: opacity var(--animation-timing) ease-in;
  transition: opacity var(--animation-timing) ease-in;
}

.moveDown {
  -webkit-transition: transform var(--animation-timing) ease-in;
  -moz-transition: transform var(--animation-timing) ease-in;
  -ms-transition: transform var(--animation-timing) ease-in;
  -o-transition: transform var(--animation-timing) ease-in;
  transition: transform var(--animation-timing) ease-in;
}

/* Styling for the container the holds the header text. This is positioned absolutely *relative* to the .headerContent class. */
#headerContainer {
  display: flex;
  justify-content: center;
  background-size: cover;
  width: 100%;
  height: 50vh;
}

/* Styles for just the header text itself. */
#header {
  font-size: 7vw; /* Adjusts based on the size of the screen */
  font-family: "Spinnaker", sans-serif;
  font-weight: bold;
  color: white;
  text-align: center;
  align-self: center;
}

/* Blue container that holds the subheader text. */
#subheaderContainer {
  display: flex;
  align-self: stretch;
  justify-content: center;
  background-color: var(--blue);
  padding: 20px;
  margin-bottom: 10px;
}

/* Styling for the actual subheader text. */
#subheader {
  color: white;
  font-size: 20px;
  font-family: "Spinnaker", sans-serif;
  text-align: center;
}

/* Desktop only. */
@media only screen and (min-width: 768px) {
  #header {
    font-size: 4vw;
    width: 90%;
  }

  #subheader {
    /* font-size: 35px; */
    font-size: 2vw;
  }
}

/* Targets iPads and other similar tablets. */
@media only screen and (max-width: 1024px) {
  #header {
    font-size: 6vw; /* Adjusts based on the size of the screen */
  }

  #subheader {
    font-size: 30px;
  }
}

/* Specific font styles for smartphone mobile devices. */
@media only screen and (max-width: 768px) {
  #header {
    font-size: 6.5vw; /* Adjusts based on the size of the screen */
  }

  #subheader {
    font-size: 20px;
  }
}

/* Specific header font for mobile devices in landscape mode. */
@media only screen and (max-width: 768px) and (orientation: landscape) {
  #header {
    font-size: 4vw;
  }
}
