/* Used for the main div in the Accordion component; wraps all the AccordionItem components. */
.accordionWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 25px;
  border: 3px solid var(--blue);
  overflow: hidden;
}

/* Main div for all the AccordionItems */
.accordionItemContainer {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  align-self: stretch;
  background-color: white; /* Ensures that the content text isn't visible when it's supposed to be closed. */
  border-bottom: 2px solid var(--blue);
  border-top: none;
  border-left: none;
  border-right: none;
  transition: all 0.3s ease;

  /* Makes the content section centered */
  padding-left: 25px;
  padding-right: 25px;
}

/* Style used for the header of the AccordionItem. */
.accordionHeader {
  display: flex;
  justify-content: space-between; /* Separates the header text from the arrow. */
  align-items: center;
  align-self: stretch;
  font-family: "Spinnaker", sans-serif;
  font-size: 13px;
  color: black;
  text-align: left;
  cursor: pointer;
  padding: 15px 5px 15px 5px;
  border: none;
  background: none;
}

/* Prevents the arrow icons from downscaling when the text takes up too much room in the accordionHeader div (mobile only). */
#headerText {
  max-width: 75%;
}

/* Used for the arrow icon that is off to the right of the header text. */
.arrow {
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.5s ease;
}

/* When the AccordionItem is clicked, the arrow (initially pointed down) is rotated so that it is pointed up.  */
.arrowOpened {
  transform: rotate(180deg);
}

/* Styles used for the chunk of text that appears when the AccordionItem is clicked. */
.accordionContent {
  display: flex;
  align-self: stretch;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  transition: all 0.5s;
  padding-left: 25px;
  padding-right: 25px;
  font-family: "Roboto";
  color: black;
  font-size: 20px;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  height: auto;
}

/* When the content section is closed, it should be extremely condensed to the point where it is invisible. */
.contentClosed {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

/* When the content section is opened, it should be expanded. Additionally, the text should become more spaced out so it looks nicer. */
.contentOpened {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

/* 
  Used by the outermost div in the AccordionItem component.
  It just ensures that the entire component (except for the content section) turns a certain color when it is
  clicked.
*/
.opened {
  background-color: #cee3ff; /* Light Blue, but opaque */
}

/* Desktop styles */
@media only screen and (min-width: 768px) {
  .accordionHeader {
    font-size: 15px;
  }

  #headerText {
    max-width: 90%;
  }
}
