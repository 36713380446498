table {
  font-family: "Spinnaker", sans-serif;
  border-spacing: 0;
  table-layout: fixed;
  width: 97%;
  margin: 10px;
  /* Must manually set the bottom of the table's border color. */
  border-bottom: 1px solid var(--lightBlue);
  border-radius: 15px;
}

/* Styles for each of the table's cells. */
td {
  font-size: 15px;
  text-align: left;
  padding: 9px;
  border-left: 1px solid var(--lightBlue);
  border-right: 1px solid var(--lightBlue);
}

/* Styles for the table's header. */
th {
  font-size: 25px;
  text-align: center;
  padding: 4px;
  background-color: var(--blue);
  color: white;
}

/* Makes all the checkmarks center aligned. */
td:not(:first-child) {
  text-align: center;
}

/* Makes the first column wider than the rest so the text doesn't carry onto another line. */
table tr th:nth-child(1) {
  width: 35%;
}

/* Makes the background of every other row light blue. */
tr:nth-child(even) {
  background-color: var(--lightBlue);
}

/* Make the entire table have rounded corners by targeting each of the corner cells and adjusting their border radii. */
th:first-of-type {
  border-top-left-radius: 15px;
}
th:last-of-type {
  border-top-right-radius: 15px;
}
tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 15px;
}
tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 15px;
}
/*--------------------------------------*/

/* Style for the headers that introduce the table and the testimonials. */
h1 {
  font-family: "Spinnaker", sans-serif;
  font-size: 25px;
  text-align: center;
  margin: 25px;
}

.table-cell-header {
  font-family: "Spinnaker", sans-serif;
  font-size: 20px;
  text-align: center;
  margin: 15px;
}

/* Styles for each of the logos in the table. */
.img {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 225px;
  align-self: center;
  margin: auto;
}

/* Need to make the NAU logo smaller than the rest. */
#nauImg {
  width: 70%;
}

/* Targets the div that contains both the arrows and the Slider component itself. */
.carousel-container {
  width: 90%; /* Control's the width of the entire component. */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--lightBlue);
  border-radius: 25px;
}

/* Styles for the actual Slider component. */
.carousel {
  width: 75%; /* Controls the width of just the Slider component. */
  padding-top: 15px;
  padding-bottom: 25px;
  vertical-align: middle;
  margin-left: 40px;
  margin-right: 40px;
}

/* Targets the div within the slider that's actually moving. */
.carousel-inner {
  padding-bottom: 20px; /* Provides some space between the dots and the testimonial text. */
}

/* Allows the arrows to animate when hovered. */
.carousel-arrow {
  transition: all 0.2s ease-in-out;
}
.carousel-arrow:hover {
  cursor: pointer;
  transform: scale(1.25);
}
/*--------------------------*/

/* Targets the div that encapsulates each chunk of testimonial text. */
.quote-container {
  text-align: center;
  font-family: "Spinnaker", sans-serif;
  height: 250px;
  display: flex !important; /* Ensures that all the slides are vertically aligned center. */
  justify-content: center;
  align-items: center;
  justify-self: stretch;
  width: 20%;
}

/* Targets the dots at the bottom of the Carousel that tell the user which slide they're on. */
.dots {
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
}

/* Make the dot opaque when its associated slide is viewable. */
.activeDot {
  opacity: 1;
  transform: scale(1.4);
}

/* Adds an animation whenever the user hovers over a dot. */
.dots:hover {
  cursor: pointer;
  opacity: 1;
  transform: scale(1.4);
}

/* Desktop styles */
@media only screen and (min-width: 768px) {
  /* Style for the headers that introduce the table and the testimonials. */
  h1 {
    font-size: 35px;
  }

  .table-cell-header {
    font-size: 40px;
  }

  /* Styles for each of the table's cells. */
  td {
    font-size: 18px;
  }

  /* Styles for the actual quote text */
  .quote {
    font-size: 23px;
  }

  /* Styles for the actual text below the quote that identifies who said the quote. */
  .identity {
    font-size: 18px;
  }

  /* Need to make the NAU logo smaller than the rest. */
  #nauImg {
    width: 40%;
    height: auto;
    margin: auto;
  }

  #unlvImg {
    width: 65%;
  }
}

/* Tablet devices */
@media only screen and (max-width: 1024px) {
  /* h1 {
    font-size: 30px;
  } */

  /* Styles for the actual quote text */
  .quote {
    font-size: 20px;
  }

  /* Styles for the actual text below the quote that identifies who said the quote. */
  .identity {
    font-size: 16px;
  }

  /* Slightly smaller image sizes on tablets. */
  .img {
    width: 90%;
  }

  #nauImg {
    width: 50%;
  }
}

/* Specific text styles for smartphone mobile devices. */
@media only screen and (max-width: 768px) {
  /* Styles for the actual quote text */
  .quote {
    font-size: 15px;
    font-weight: bold;
  }

  /* Styles for the actual text below the quote that identifies who said the quote. */
  .identity {
    font-size: 12px;
  }

  /* Slightly larger image sizes on smartphones. */
  .img {
    width: 100%;
  }

  #nauImg {
    width: 62%;
  }
}
/* Styles for h3 elements */
h3 {
  font-family: "Spinnaker", sans-serif;
  font-size: 25px;
  text-align: left; /* Set text alignment to left */
  margin: -20px;
}
