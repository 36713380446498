:root {
  --animation-timing: 1.25s;
}

.invisible {
  opacity: 0;
  transform: translateY(-35px);
}

/**
* Must have this class in order for the two animations to occur at the same time when needed.
* You cannot set an element to the two individual classes and have them work at the same time.
*/
.fadeIn-moveDown {
  -webkit-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -moz-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -ms-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -o-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
}

.fadeIn {
  -webkit-transition: opacity var(--animation-timing) ease-in;
  -moz-transition: opacity var(--animation-timing) ease-in;
  -ms-transition: opacity var(--animation-timing) ease-in;
  -o-transition: opacity var(--animation-timing) ease-in;
  transition: opacity var(--animation-timing) ease-in;
}

.moveDown {
  -webkit-transition: transform var(--animation-timing) ease-in;
  -moz-transition: transform var(--animation-timing) ease-in;
  -ms-transition: transform var(--animation-timing) ease-in;
  -o-transition: transform var(--animation-timing) ease-in;
  transition: transform var(--animation-timing) ease-in;
}

.contact-section {
  display: flex;
  flex-direction: row;
  width: 97%;
  justify-content: space-evenly;
  margin-left: 2%;
  margin-right: 2%;
}

.box-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  background-color: #3c3837;
  color: white;
  font-family: "Roboto";
  line-height: 1.5;
  padding: 20px;
  border-radius: 20px;
  margin: 10px;
  min-width: 20%;
}

.box-header-img {
  display: flex;
  align-self: center;
  object-fit: scale-down; /* Preserves aspect ratio */
  width: 100%;
  height: auto;
  max-width: 300px;
  padding: 10px 10px 20px 10px;
}

.box-text {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin: 5px;
}

/* Image-specific sizing changes. */
#csn {
  padding-bottom: 0;
}
#nau {
  width: 55%;
}

.website-link {
  color: var(--red);
}

.email-link {
  color: white;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
}

/* Desktop styles */
@media only screen and (min-width: 768px) {
  .contact-section {
    flex-wrap: nowrap;
  }

  .box-text {
    font-size: 1.15vw;
  }
}

/* Tablet styles */
@media only screen and (max-width: 1024px) {
  .contact-section {
    flex-wrap: wrap;
  }

  .box-text {
    font-size: 20px;
  }

  /* Prevents awkward flex wrapping when the screen is resized. */
  .box-header-img {
    max-width: 280px;
  }
}

/* Specific mobile styles. */
@media only screen and (max-width: 768px) {
  .box-container {
    width: 80%;
  }

  .box-header-img {
    max-width: 200px;
  }

  .box-text {
    font-size: 18px;
  }

  /* The NAU logo needs to be slightly smaller on mobile. */
  #nau {
    width: 40%;
  }
}
