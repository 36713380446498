.boxes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: stretch;
  align-content: center;
  justify-content: space-evenly;
  margin-left: 1%;
  margin-right: 1%;
}

.image-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 32vw;
  max-height: 32vw;
  background-color: var(--black);
  color: white;
  padding: 25px;
  margin: 10px;
  border-radius: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.images {
  display: flex;
  align-self: center;
  width: 100%;
  height: auto;
}

/*
  Whenever the user hovers over an image-container or focuses it with the TAB button (accessibility),
  it will scale up slightly.
*/
.image-container:hover,
.image-container:focus {
  transform: scale(1.05);
  cursor: pointer;
}

.csnEmail {
  font-weight: bold;
  color: white;
}

.websiteLink {
  font-weight: bold;
  color: white;
}

#csnText {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 15px;

  transition: all 0.3s ease-in-out;
  transform: scale(0); /* Initially hidden. */
  opacity: 0;
}

#csnImage {
  object-fit: scale-down;

  transition: all 0.3s ease-in-out;
  transform: scale(1); /* Initially shown. */
  opacity: 1;
}

#csnBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: auto;
  outline: none;
}

/*
  Whenever the user hovers over the CSN block or focuses it with the TAB button (accessibility),
  the CSN logo shrinks and reveals the text, which grows into view.
*/
#csnBlock:hover > #csnText,
#csnBlock:focus > #csnText {
  transform: scale(1);
  opacity: 1;
}
#csnBlock:hover > #csnImage,
#csnBlock:focus > #csnImage {
  transform: scale(0);
  opacity: 0;
}
/*
  Whenever the user hovers over an image-container or focuses it with the TAB button (accessibility),
  it will scale up slightly.
*/
.image-container:hover,
.image-container:focus {
  transform: scale(1.05);
  cursor: pointer;
}
.cccEmail {
  font-weight: bold;
  color: white;
}

#cccText {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 15px;

  transition: all 0.3s ease-in-out;
  transform: scale(0); /* Initially hidden. */
  opacity: 0;
}

#cccImage {
  object-fit: scale-down;

  transition: all 0.3s ease-in-out;
  transform: scale(1); /* Initially shown. */
  opacity: 1;
}

#cccBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: auto;
  outline: none;
}
/*
  Whenever the user hovers over the CCC block or focuses it with the TAB button (accessibility),
  the CCC logo shrinks and reveals the text, which grows into view.
*/
#cccBlock:hover > #cccText,
#cccBlock:focus > #cccText {
  transform: scale(1);
  opacity: 1;
}
#cccBlock:hover > #cccImage,
#cccBlock:focus > #cccImage {
  transform: scale(0);
  opacity: 0;
}
/*
  Whenever the user hovers over an image-container or focuses it with the TAB button (accessibility),
  it will scale up slightly.
*/
.image-container:hover,
.image-container:focus {
  transform: scale(1.05);
  cursor: pointer;
}
.cccEmail {
  font-weight: bold;
  color: white;
}

#cccText {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 15px;

  transition: all 0.3s ease-in-out;
  transform: scale(0); /* Initially hidden. */
  opacity: 0;
}

#cccImage {
  object-fit: scale-down;

  transition: all 0.3s ease-in-out;
  transform: scale(1); /* Initially shown. */
  opacity: 1;
}

#cccBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: auto;
  outline: none;
}
/*
  Whenever the user hovers over the UNLV block or focuses it with the TAB button (accessibility),
  the UNlV logo shrinks and reveals the text, which grows into view.
*/
#unlvBlock:hover > #unlvText,
#unlvBlock:focus > #unlvText {
  transform: scale(1);
  opacity: 1;
}
#unlvBlock:hover > #unlvImage,
#unlvBlock:focus > #unlvImage {
  transform: scale(0);
  opacity: 0;
}
/*
  Whenever the user hovers over an image-container or focuses it with the TAB button (accessibility),
  it will scale up slightly.
*/
.image-container:hover,
.image-container:focus {
  transform: scale(1.05);
  cursor: pointer;
}
.unlvEmail {
  font-weight: bold;
  color: white;
}

#unlvText {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 15px;

  transition: all 0.3s ease-in-out;
  transform: scale(0); /* Initially hidden. */
  opacity: 0;
}

#unlvImage {
  object-fit: scale-down;

  transition: all 0.3s ease-in-out;
  transform: scale(1); /* Initially shown. */
  opacity: 1;
}

#unlvBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: auto;
  outline: none;
}
/*
  Whenever the user hovers over the NAU block or focuses it with the TAB button (accessibility),
  the NAU logo shrinks and reveals the text, which grows into view.
*/
#nauBlock:hover > #nauText,
#nauBlock:focus > #nauText {
  transform: scale(1);
  opacity: 1;
}
#nauBlock:hover > #nauImage,
#nauBlock:focus > #nauImage {
  transform: scale(0);
  opacity: 0;
}
/*
  Whenever the user hovers over an image-container or focuses it with the TAB button (accessibility),
  it will scale up slightly.
*/
.image-container:hover,
.image-container:focus {
  transform: scale(1.05);
  cursor: pointer;
}
.nauEmail {
  font-weight: bold;
  color: white;
}

#nauText {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 15px;

  transition: all 0.3s ease-in-out;
  transform: scale(0); /* Initially hidden. */
  opacity: 0;
}

#nauImage {
  object-fit: scale-down;

  transition: all 0.3s ease-in-out;
  transform: scale(1); /* Initially shown. */
  opacity: 1;
}

#nauBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: auto;
  outline: none;
}
/* Make the NAU logo slightly smaller than the rest. */
#nauImage {
  width: 80%;
}

/* Slightly increase the size of the CCC logo. */
#cccImage {
  width: 105%;
}

/* Desktop styles */
@media only screen and (min-width: 768px) {
  .boxes-container {
    flex-wrap: nowrap;
  }

  .image-container:focus {
    outline: auto;
  }

  #csnText {
    font-size: 1.3vw;
  }

  #cccText {
    font-size: 1.3vw;
  }

  #nauText {
    font-size: 1.3vw;
  }
  
  #unlvText {
    font-size: 1.3vw;
  }
}

/* Tablet styles */
@media only screen and (max-width: 1024px) {
  .boxes-container {
    flex-wrap: wrap;
  }

  .image-container:focus {
    outline: none;
  }

  #csnText {
    font-size: 18px;
  }
}

/* Specific mobile styles. */
@media only screen and (max-width: 768px) {
  .boxes-container {
    flex-direction: column;
  }

  .image-container {
    max-width: 80%;
  }

  .images {
    padding: 20px;
    width: 75%;
  }

  #nauImage {
    width: 55%;
  }

  #csnText {
    font-size: 4vw;
  }

  #cccText {
    font-size: 4vw;
  }

  .image-container:focus {
    outline: none;
  }
}
