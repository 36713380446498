/* Variable used for the timing of the fadeIn and moveDown animations. */
:root {
  --animation-timing: 1.25s;
}

/* Pre-animation state of the header and subheader when the page first loads. */
.invisible {
  opacity: 0;
  transform: translateY(-35px);
}

/**
  * Use this class when you specifically want both the fadeIn and moveDown animations to 
  * occur at the same time. The animations do not work properly if an element has both of
  * them separately.
*/
.fadeInMoveDown {
  -webkit-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -moz-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -ms-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  -o-transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
  transition: opacity var(--animation-timing) ease-in,
    transform var(--animation-timing) ease-in;
}

.fadeIn {
  -webkit-transition: opacity var(--animation-timing) ease-in;
  -moz-transition: opacity var(--animation-timing) ease-in;
  -ms-transition: opacity var(--animation-timing) ease-in;
  -o-transition: opacity var(--animation-timing) ease-in;
  transition: opacity var(--animation-timing) ease-in;
}

.moveDown {
  -webkit-transition: transform var(--animation-timing) ease-in;
  -moz-transition: transform var(--animation-timing) ease-in;
  -ms-transition: transform var(--animation-timing) ease-in;
  -o-transition: transform var(--animation-timing) ease-in;
  transition: transform var(--animation-timing) ease-in;
}

/* 
  Styles for the Slider component that contains all the background images.
  Just as an FYI, NEVER put display: flex here. This causes annoying graphical glitches when resizing. 
*/
.sliderContainer {
  width: 100%;
  height: 61vh; /* Changes based on the height of the screen. */
  background-color: black; /* Allows the images to have a slight black tint to them. */
  margin-bottom: -5px; /* Gets rid of an annoying bottom margin supplied by the Slider component that cannot be manually removed. */
}

/* 
  Allows the actual header text to be positioned on top of the images.
  i.e. The position of the header text is *relative* to this parent div.
*/
.headerContent {
  position: relative;
  overflow: hidden;
}

/* Styles for just the header text itself. */
#header {
  width: 95%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6.5vw; /* Adjusts based on the size of the screen */
  font-family: "Spinnaker", sans-serif;
  color: white;
  text-align: center;
}

/* Class for each of background images. */
.background {
  width: 100%;
  height: 61vh; /* Changes based on the height of the screen. */
  object-fit: cover; /* Makes the images take up the entire space given. */
  object-position: 50% 38%; /* Controls the alignment of the images. */
  margin: auto;
  opacity: 0.7;
  min-height: 0;
  min-width: 0;
}

/* Blue container that holds the subheader text. */
#subheaderContainer {
  display: flex;
  align-self: stretch;
  justify-content: center;
  background-color: var(--blue);
  padding: 20px;
  margin-bottom: 10px;
}

/* Styling for the actual subheader text. */
#subheader {
  color: white;
  font-size: 20px;
  font-family: "Spinnaker", sans-serif;
  text-align: center;
}

/* Mobile devices in landscape mode. */
@media only screen and (max-width: 768px) and (orientation: landscape) {
  #header {
    font-size: 4vw;
  }
}

/* Desktop only. */
@media only screen and (min-width: 768px) {
  #header {
    font-size: 4vw;
    width: 90%;
  }

  #subheader {
    font-size: 2vw;
  }
}

/* Targets iPads and other similar tablets. */
@media only screen and (max-width: 1024px) {
  #header {
    font-size: 6vw; /* Adjusts based on the size of the screen */
  }

  #subheader {
    font-size: 30px;
  }
}

/* Specific font styles for smartphone mobile devices. */
@media only screen and (max-width: 768px) {
  #header {
    font-size: 6.5vw; /* Adjusts based on the size of the screen */
  }

  #subheader {
    font-size: 20px;
  }
}

/* Specific header font for mobile devices in landscape mode. */
@media only screen and (max-width: 768px) and (orientation: landscape) {
  #header {
    font-size: 4vw;
  }
}
