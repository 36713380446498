/* Denotes a section for which the content-header and the content classes can be used. */
.page-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: 90%;
  margin: 10px 10% 5px 10%;
}

/* Style for all the headers. */
.page-header {
  font-family: "Spinnaker", sans-serif;
  font-size: 28px;
  text-align: center;
  margin: 15px 0 15px 0;
}

/* Style for all the chunks of text. */
.bullets {
  width: 80%;
  line-height: 1.5;
  font-size: 18px;
  text-align: left;
  font-family: "Roboto";
}

@media only screen and (min-width: 768px) {
  /* Style for all the chunks of text. */
  .bullets {
    font-size: 20px;
  }

  /* Style for all the headers. */
  .page-header {
    text-align: left;
    font-size: 35px;
  }
}
