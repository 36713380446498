/* Global colors */
:root {
  --blue: #255ba8;
  --yellow: #e4be37;
  --red: #fd5c5f;
  --black: #3c3837;
  --lightBlue: #90bbf862;
}

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  /* Prevents horizontal scrolling */
  overflow-x: hidden;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Global className to use for the main wrapper container of every page. */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

@font-face {
  font-family: "Spinnaker";
  src: url("./assets/fonts/Spinnaker/Spinnaker-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Italic";
  src: url("./assets/fonts/Roboto/Roboto-Italic.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
}

/* Changes the background color to blue on mobile devices when they are in landscape mode so the sides are colored. */
@media only screen and (max-width: 768px) and (orientation: landscape) {
  html,
  body {
    background-color: var(--blue);
  }
}
