/*--------------------------------------- UNIVERSAL STYLES ---------------------------------------*/

/* Ensures that the navbar will always stick to the top of the screen. */
.navbar-sticky {
  position: fixed;
  width: 100%;
  transition: 0.2s;
  box-shadow: 0 8px 35px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

/* Styling for the navbar container that holds all the logos and navigation links */
.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  background-color: var(--blue);
  padding: 5px 2vw 5px 2vw;
  overflow-x: hidden;
  min-height: 5vh;
}

.pageLink {
  color: var(--yellow);
  text-decoration: none;
  font-weight: bold;
  font-size: 22px;
  transition: all 0.25s ease;
}

.active-link {
  color: var(--red);
}

.pageLink:hover {
  color: var(--red);
}

/*--------------------------------------- MOBILE-ONLY STYLES ---------------------------------------*/

/* Style for the background when the mobile navigation drawer is open. */
.dim-background {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  height: 200vh;
  width: 100vw;
  z-index: 2;
  transition: opacity 0.5s;
  opacity: 1;
}

/* Resets the dimming so the background is no longer dark. */
.light-background {
  opacity: 0;
}

.logo-container {
  display: none;
}

.skip-link {
  display: none;
}

#menu-icon {
  display: flex;
  align-self: flex-end;
  position: absolute;
  z-index: 4;
  transition: all 0.5s;
  cursor: pointer;
}

/* Mobile only: shows the logo off to the far left of the navbar. */
#lsamp-logo-mobile {
  max-height: 40px;
  cursor: pointer;
}

/* Either the hamburger icon or the x icon should shrink or grow depending on whether the nav drawer is opened or not. */
.icon-hidden {
  transform: scale(0);
  opacity: 0;
}
.icon-visible {
  transform: scale(1);
  opacity: 1;
}

/* Navigation drawer styles for mobile devices. */
.link-container {
  display: flex;
  align-items: center;
  position: fixed;
  height: auto; /* Stretch across the entire height of the screen. */
  width: 0; /* When the drawer is opened, the width is increased. Zero indicates that the drawer is closed. */
  top: 0;
  right: 0;
  transition: all 0.6s; /* Determines how fast the drawer opens and closes. */
  text-align: center;
  line-height: 3;
  background-color: var(--blue);
  box-shadow: 0 8px 35px rgba(0, 0, 0, 0.2);
  z-index: 3;
  top: -15px; /* Move the text up ever so slightly to make it look more centered. */
}

/* This div actually holds all the navigation links. It makes styling/targeting them easier. */
.links {
  height: 100vh;
  width: 100vw;
  min-width: 60vw; /* Makes it so that the text actually sticks to the back ground relative to how far the nav drawer has opened/closed */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  list-style-type: none;
  text-decoration: none;
  white-space: nowrap; /* Prevents the text from wrapping and moving when the drawer is opened/closed. */
  flex-wrap: nowrap;
  padding: 0;
}

/*--------------------------------------- MOBILE LANDSCAPE ---------------------------------------*/
/* Whenever a mobile device is in landscape mode, the line height should decrease so all the nav links are still in view. */
@media only screen and (max-width: 768px) and (orientation: landscape) {
  .link-container {
    line-height: 2.5;
    top: -25%; /* Move the text up slightly so it isn't near the edge of the phone. */
  }
}

/*--------------------------------------- TABLETS ---------------------------------------*/
/* Targets iPads and other similar tablets. */
@media only screen and (max-width: 1075px) {
  .pageLink {
    font-size: 16px; /* Decrease the size of the font slightly so it doesn't look bigger than the images. */
  }
}

/*--------------------------------------- DESKTOP STYLES ---------------------------------------*/
/* Targets all devices that have a width that is at least bigger than the iPhone 8 Plus. */
@media only screen and (min-width: 768px) {
  /* Styling for the hidden accessibility "Skip to Content" button. */
  .skip-link {
    display: block;
    position: absolute; /* Positioned absolutely relative to the navbar. */
    left: 0;
    top: 100%;
    z-index: 100;
    background-color: var(--blue);
    color: var(--yellow);
    transition: all 0.15s;
    transform: translateY(-100%); /* Initially hidden from view. */
    opacity: 0; /* Initially hidden from view. */
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    padding: 5px;
    pointer-events: none;
  }

  /* When the "Skip to Content" button is tabbed to, it fades in and lowers down smoothly. */
  .skip-link:focus {
    transform: translateY(0%);
    opacity: 1;
    pointer-events: all;
  }

  /* 
    Holds all of the navigation links. 
    Some of these styles are unnecessary/redundant in order to prevent the mobile styles from interfering with it.
  */
  .link-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: auto; /* width, top, right, and position help differentiate this from the mobile design */
    height: auto;
    top: auto;
    right: auto;
    position: relative;
    transition: none;
    list-style-type: none;
    margin-bottom: auto;
    margin-top: auto;
    padding: 0;
    box-shadow: none;
    line-height: 2;
    top: 0;
  }

  /* Actual style for all the links. */
  .links {
    position: relative;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
    white-space: normal;
    padding: 0;
    margin: auto;
  }

  /* Holds all the logos towards the top. */
  .logo-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5px; /* Provides space between the logos and the links. */
  }

  /* Standard styling for every logo image. Try not to touch this if possible*/
  .logo {
    display: block;
    width: auto;
    height: auto;
    max-width: 20vw;
  }

  /*
    Specific logo styling for every logo image, since not all the images are uniform.
    In general, the height property correlates to the logo's size on desktop, whereas
    its max-height correlates with its size on mobile platforms (i.e. iPads). 
  */
  #unlv-logo {
    height: 60px;
    max-height: 4.5vw;
  }
  #csn-logo {
    height: 100px;
    max-height: 7.5vw;
  }
  #lsamp-logo {
    max-height: 100px;
    height: 7vw;
  }
  #coco-logo {
    height: 90px;
    max-height: 6vw;
  }
  #nau-logo {
    height: 85px;
    max-height: 6vw;
  }

  /* Since this query is for desktop displays only, remove the hamburger and x icons. */
  #menu-icon {
    display: none;
  }
}
