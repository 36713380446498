/* Denotes a section for which the content-header and the content classes can be used. */

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 10% 45px 10%;
}

/* Style for all the headers. */
.content-header {
  font-family: "Spinnaker", sans-serif;
  font-size: 28px;
  align-self: center;
  text-align: center;
  margin: 15px 0 15px 0;
}

/* Style for all the chunks of text. */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  line-height: 1.5;
  font-size: 18px;
  font-family: "Roboto";
}

hr {
  border-top: solid black 1.5px;
  width: 85%;
}

#louis-section {
  flex-direction: column-reverse;
}

/* Special styling used for the LSAMP logo in the "What is LSAMP?" section. */
#lsamp-picture {
  /* width: 100%; */
  max-width: 250px;
  height: auto;
}

/* Special styling used for the Louis Stokes picture in the "History" section. */
#louisStokes-picture {
  /* width: 100%; */
  max-width: 425px; /* Picture will take up the full width on mobile. */
  height: auto;
  border-radius: 20px;
}

/* Shadows used to make the above pictures stand out more. */
/* .dropShadowRight {
  filter: drop-shadow(12px 12px 25px rgba(0, 0, 0, 0.4));
}
.dropShadowLeft {
  filter: drop-shadow(-12px 12px 25px rgba(0, 0, 0, 0.3));
} */

/* 
  Provides the actual styling that's applied to the YouTube video. If you want to change how it looks, change this class only.
  All of the child styles (.iframe-container and .responsive-iframe) are used to preserve its aspect ratio only.
*/
.video {
  width: 100%;
  max-width: 70vw; /* Prevents the thumbnail from overflowing off the screen. */
  height: auto;
  overflow: hidden;
  border: 5px solid var(--blue);
  border-radius: 25px;
  background-color: var(--blue);
  margin: 10px 25px 25px 25px;
  z-index: 0; /* Prevents the video thumbnail from displaying in front of its border. */
}

/* 
  Contains the <iframe> tag and makes sure it preserves its aspect ratio. 
  (DO NOT TOUCH)
*/
.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* 
  This styling applies to the <iframe> tag itself. It rests within the bounds of the .iframe-container, which has relative positioning. 
  (DO NOT TOUCH)
*/
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/*--------------------------------------- DESKTOP STYLES ---------------------------------------*/
@media only screen and (min-width: 768px) {
  /* Style for all the chunks of text. */
  .content {
    display: flex;
    flex-direction: row;
    line-height: 1.5;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    font-family: "Roboto";
  }

  /* Style for all the headers. */
  .content-header {
    align-self: flex-start;
    text-align: left;
    font-size: 35px;
  }

  .video {
    max-width: 50vw; /* Makes the video thumbnail larger on desktop devices. */
  }

  #louis-section {
    flex-direction: row;
  }

  /* Add an extra margin so the image can be spaced away from the text a bit. */
  #lsamp-picture {
    margin-left: 50px;
    /* max-width: 400px; */
  }

  /* Add an extra margin so the image can be spaced away from the text a bit. */
  #louisStokes-picture {
    margin-right: 50px;
    /* max-width: 80vw; */ /* Picture will take up the full width on mobile. */
  }
}

/* Targets iPads and other similar tablets. */
@media only screen and (max-width: 1024px) {
  .content {
    flex-direction: column;
  }

  #louis-section {
    flex-direction: column-reverse;
  }

  #louisStokes-picture {
    margin: 10px 0 0 0;
    max-width: 50vw;
  }

  #lsamp-picture {
    margin: 10px 0 0 0;
    max-width: 30vw;
  }
}

/* Specific image styles for smartphone mobile devices. */
@media only screen and (max-width: 768px) {
  /* Special styling used for the LSAMP logo in the "What is LSAMP?" section. */
  #lsamp-picture {
    max-width: 250px;
  }

  /* Special styling used for the Louis Stokes picture in the "History" section. */
  #louisStokes-picture {
    max-width: 70vw; /* Picture will be the same size as the video. */
  }
}
