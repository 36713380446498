.footer-container {
  background-color: #f2f2f2;
  bottom: 0;
  width: 100%;
  margin-top: 10px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  margin-top: 10px;
  padding: 0 25px 0 25px;
}

#caeo-container {
  text-align: center;
  margin: auto;
}

#caeo-logo {
  height: auto;
  width: 100%;
  max-width: 400px;
  margin-left: -7px;
}

#nsf-logo {
  height: auto;
  width: 100%;
  max-width: 80px;
  margin: 10px;
}

#nsf-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin: 10px;
  text-align: center;
}

/* Only appears on mobile devices. */
#divider {
  display: block;
  border: 0.5px solid black;
}

/* Desktop devices */
@media only screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    font-size: 16px;
  }

  #caeo-logo {
    max-width: 33vw;
  }

  #nsf-logo {
    max-width: 7vw;
  }

  #caeo-container {
    text-align: left;
    margin: 0;
  }

  #nsf-container {
    flex-direction: row;
    text-align: left;
  }

  #divider {
    display: none;
  }
}
