/* Container that encompasses the scroll button. This makes it so that both the inner and outer buttons scale uniformly when hovered over.  */
.scroll-btn-container {
  position: fixed;
  bottom: 0;
  right: 0;
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.4));
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  transform: translate(25vw);
}

/* 
  Styles for the individual button icons. There needs to be an outer one and an inner one.  
  The outer one provides the blue background color, while the inner one provides the yellow arrow color.
*/
.scroll-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 30px;
}

/* Button comes into view after the user has scrolled down a certain amount. */
.visible {
  transform: translate(0);
}

/* When the button is being pressed down, shrink it a little. */
.scroll-btn-container:active {
  transform: scale(0.9);
}

/* Specific button press and hover styles for desktop platforms only */
@media only screen and (min-width: 1024px) {
  .scroll-btn-container:hover {
    transform: scale(1.1);
  }

  .scroll-btn-container:active {
    transform: scale(1);
  }
}
