.button {
  font-size: 18px;
  font-family: "Spinnaker", sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 25px;
  /* margin: 25px; */
  border-radius: 20px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  cursor: pointer;
  opacity: 0.85;
  transform: scale(1.03);
}

.button:active {
  transform: scale(1);
}

@media only screen and (min-width: 768px) {
  .button {
    font-size: 25px;
  }
}
