/* Styles for the PDF document. */
.pdf {
  border: 2px solid var(--black);
}

.previous-issues {
  width: 90%;
}

.previous-issues > h1 {
  text-align: left;
}

.previous-issues > ul {
  font-size: 20px;
  font-family: "Spinnaker", sans-serif;
}

.previous-issues > ul > li {
  margin: 20px;
}
